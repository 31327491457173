export const staticMenuOptions = user => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },
  {
    key: 'alerts-notifications',
    path: '/alerts',
    name: 'Alerts+Notifications',
    icon: 'gear-2',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-notifications-metrics-by-app-id')
  },
  {
    key: 'monitoring',
    name: 'Monitoring',
    icon: 'activity',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-user-logs-by-appId'),
    subItems: [
      {
        key: 'logs',
        path: '/monitoring/log-table',
        name: 'Logs',
        icon: 'devices',
        shouldShow: true
      },
      {
        key: 'status',
        path: '/monitoring/status',
        name: 'Status',
        icon: 'activity',
        shouldShow: true
      },
    ]
  },

  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];
