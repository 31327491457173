<script>
  import AlertScreen from '@cox2m/city-services-ui-components/src/components/alerts/AlertsScreen.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';

  import {menuOpts} from '../../stores';
</script>

<MainScreen title="alerts" menuOpt={$menuOpts}>
  <div slot="main-dashboard">
    <AlertScreen appName="Kiosk" />
  </div>
</MainScreen>
