import CurbsideStatusMonitoring from './forms/monitoring/CurbsideStatusMonitoring.svelte';
import MonitoringRedirectPage from './forms/monitoring/MonitoringRedirectPage.svelte';
import Monitoring from './forms/monitoring/Monitoring.svelte';
import AlertsPage from './forms/alerts/AlertsPage.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/alerts': AlertsPage,
  '/monitoring': MonitoringRedirectPage,
  '/monitoring/status': CurbsideStatusMonitoring,
  '/monitoring/log-table': Monitoring,

  '*': NotFoundPage
};

export const openRoutes = []

export default routes
